const import_fields: any = {
  company: [
    {
      name: 'Entit',
      required: 'Yes',
      unique: 'No',
      type: 'String',
      description:
        'Import entity Type, must be <strong>Company</strong>, this prevents users from importing the wrong entity type',
    },
    {
      name: 'Name',
      required: 'Yes',
      unique: 'Yes',
      type: 'String',
      description: 'Name of the business entity',
    },
    {
      name: 'Type',
      required: 'Yes',
      unique: 'No',
      type: 'String',
      description:
        'Type of business entity, must be any of <strong>Agency</strong>, <strong>Advertiser</strong>, <strong>Station</strong>, <strong>Publisher</strong>, <strong>Vendor</strong>, <strong>Holding</strong>',
    },
    {
      name: 'Region',
      required: 'Yes',
      unique: 'No',
      type: 'String',
      description:
        'Region of business entity, must be any of <strong>East</strong>, <strong>Midwest</strong>, <strong>West</strong>, <strong>National</strong>',
    },
    {
      name: 'Industry',
      required: 'Yes',
      unique: 'No',
      type: 'String',
      description: 'Industry of business entity',
    },
    {
      name: 'Sub Industry',
      required: 'Yes',
      unique: 'No',
      type: 'String',
      description: 'Sub Industry of business entity',
    },
    {
      name: 'Parent ID',
      required: 'No',
      unique: 'No',
      type: 'UUID',
      description: 'Parent ID of business entity',
    },
    {
      name: 'Holding ID',
      required: 'No',
      unique: 'No',
      type: 'UUID',
      description: 'Holding ID of business entity',
    },
    {
      name: 'Created At',
      required: 'No',
      unique: 'No',
      type: 'Date',
      description: 'Date business entity was created',
    },
  ],
  business_contact: [
    {
      name: 'Entity',
      required: 'Yes',
      unique: 'No',
      type: 'String',
      description:
        'Import entity Type, must be <strong>Contact</strong>, this prevents users from importing the wrong entity type',
    },
    {
      name: 'First Name',
      required: 'Yes',
      unique: 'No',
      type: 'String',
      description: 'First Name of the contact',
    },
    {
      name: 'Last Name',
      required: 'Yes',
      unique: 'No',
      type: 'String',
      description: 'Last Name of the contact',
    },
    {
      name: 'Salutation',
      required: 'No',
      unique: 'No',
      type: 'String',
      description:
        'Salutation of the contact, must be any of <strong>Mr</strong>, <strong>Ms</strong>, <strong>Mrs</strong>, <strong>Dr</strong>',
    },
    {
      name: 'Title',
      required: 'No',
      unique: 'No',
      type: 'String',
      description: 'Title of the contact',
    },
    {
      name: 'Email',
      required: 'No',
      unique: 'Yes',
      type: 'String',
      description: 'Email of the contact',
    },
    {
      name: 'Email Opt Out',
      required: 'No',
      unique: 'No',
      type: 'Boolean',
      description: 'Email Opt Out of the contact',
    },
    {
      name: 'Company ID',
      required: 'Yes',
      unique: 'No',
      type: 'UUID',
      description: 'Company ID of the contact',
    },
    {
      name: 'Owner ID',
      required: 'Yes',
      unique: 'No',
      type: 'UUID',
      description: 'Owner ID of the contact',
    },

    {
      name: 'Address Line 1',
      required: 'No',
      unique: 'No',
      type: 'String',
      description: 'Address Line 1 of the contact',
    },
    {
      name: 'Address Line 2',
      required: 'No',
      unique: 'No',
      type: 'String',
      description: 'Address Line 2 of the contact',
    },
    {
      name: 'Address Line 3',
      required: 'No',
      unique: 'No',
      type: 'String',
      description: 'Address Line 3 of the contact',
    },
    {
      name: 'Address Line 4',
      required: 'No',
      unique: 'No',
      type: 'String',
      description: 'Address Line 4 of the contact',
    },
    {
      name: 'Created At',
      required: 'No',
      unique: 'No',
      type: 'Date',
      description: 'Date contact was created',
    },
    {
      name: 'Contact Label 1',
      required: 'No',
      unique: 'No',
      type: 'String',
      description:
        'Additional contact Label, must be any of <strong>Phone</strong>, <strong>Mobile</strong>, <strong>Email</strong>, <strong>Fax</strong>',
    },
    {
      name: 'Contact Value 1',
      required: 'No',
      unique: 'No',
      type: 'String',
      description: 'Additional contact Value',
    },
    {
      name: 'Contact Label 2',
      required: 'No',
      unique: 'No',
      type: 'String',
      description:
        'Additional contact Label, must be any of <strong>Phone</strong>, <strong>Mobile</strong>, <strong>Email</strong>, <strong>Fax</strong>',
    },
    {
      name: 'Contact Value 2',
      required: 'No',
      unique: 'No',
      type: 'String',
      description: 'Additional contact Value',
    },
    {
      name: 'Contact Label 3',
      required: 'No',
      unique: 'No',
      type: 'String',
      description:
        'Additional contact Label, must be any of <strong>Phone</strong>, <strong>Mobile</strong>, <strong>Email</strong>, <strong>Fax</strong>',
    },
    {
      name: 'Contact Value 3',
      required: 'No',
      unique: 'No',
      type: 'String',
      description: 'Additional contact Value',
    },
    {
      name: 'Contact Label 4',
      required: 'No',
      unique: 'No',
      type: 'String',
      description:
        'Additional contact Label, must be any of <strong>Phone</strong>, <strong>Mobile</strong>, <strong>Email</strong>, <strong>Fax</strong>',
    },
    {
      name: 'Contact Value 4',
      required: 'No',
      unique: 'No',
      type: 'String',
      description: 'Additional contact Value',
    },
    {
      name: 'Contact Label 5',
      required: 'No',
      unique: 'No',
      type: 'String',
      description:
        'Additional contact Label, must be any of <strong>Phone</strong>, <strong>Mobile</strong>, <strong>Email</strong>, <strong>Fax</strong>',
    },
    {
      name: 'Contact Value 5',
      required: 'No',
      unique: 'No',
      type: 'String',
      description: 'Additional contact Value',
    },
  ],
}

export default import_fields
