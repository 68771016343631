
import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import ViewModel from '@/models/ViewModel'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import SystemToolsModules from '@/models/SystemToolsModules'
import { CompanyTypes } from '@/models/interface/Common'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import WebMessage from '@/models/WebMessage'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import moment from 'moment'
import SelectOption from '@/models/interface/SelectOption'
import { clone } from 'lodash'
import DatePickerDate from '@/models/DatePickerDate'
import import_fields from './import_fields'

@Component({
  components: {
    Widget,
    CompanyPicker,
    FooterNav,
    SelectPicker,
    UserPicker,
    DatePicker,
  },
})
export default class SystemTools extends ViewModel {
  @Ref() public validator!: any

  public account_manager_swap: any = {
    old_id: null,
    new_id: null,
    cutout_date: moment().startOf('year').format('YYYY-MM-DD'),
  }

  public tab_index: number = 0

  @Watch('tab_index')
  public onTabIndexChange() {
    this.cancelMerge()
  }

  public busy: boolean = false

  public checking: boolean = false

  public entity_types: any = CompanyTypes

  public import_entity_type: string = ''

  public range_date = new DatePickerDate(
    moment().subtract(7, 'day').format('YYYY-MM-DD'),
    moment().subtract(1, 'day').format('YYYY-MM-DD'),
  )

  public import_entity_options: SelectOption[] = [
    new SelectOption('Business Entity', 'company'),
    new SelectOption('Business Contact', 'business_contact'),
  ]

  public get import_fields() {
    return import_fields[this.import_entity_type]
  }

  public modal: any = {
    confirm: false,
  }

  public local_files: any = []

  public entity_changes: any = {
    destination_entity: {
      name: null,
      type: null,
    },
    old_entity: {
      name: null,
      type: null,
    },
    cutout_date: moment().startOf('year').format('YYYY-MM-DD'),
  }

  public check_informations: any = {
    media_plans: 0,
    opportunities: 0,
    invoices: 0,
    line_items: 0,
    orders: 0,
  }

  public change_type: any = {
    origin: {
      value: null,
    },
    new_type: null,
  }

  public confirmed: boolean = false

  public get tabAction() {
    switch (this.tab_index) {
      case 0:
        return 'merge_business_entity'
      case 1:
        return 'udpate_business_entity_type'
      case 2:
        return 'update_account_manager'
      case 3:
        return 'import_entities'
      case 4:
        return 'resync_metrics'
      default:
        return null
    }
  }

  public get confirmText() {
    switch (this.tabAction) {
      case 'merge_business_entity':
        return 'Merge'
      case 'udpate_business_entity_type':
        return 'Change Type'
      case 'update_account_manager':
        return 'Update'
      case 'import_entities':
        return 'Import'
      case 'resync_metrics':
        return 'Resync'
      default:
        return 'Apply'
    }
  }

  public get disableConfirm() {
    switch (this.tabAction) {
      case 'import_entities':
        return this.local_files.length === 0 || !this.import_entity_type
      case 'update_account_manager':
        return (
          !this.entity_changes.old_entity.value
          || !this.entity_changes.destination_entity.value
          || !this.entity_changes.cutout_date
        )
      case 'resync_metrics':
        return !this.range_date.start || !this.range_date.end
      default:
        return (
          this.same_types
          || this.selected_diff_types
          || this.selected_same_inputs
          || !this.selected_both_inputs
          || this.selected_same_entity
        )
    }
  }

  public get contains_items_to_change() {
    if (this.tabAction === 'udpate_business_entity_type') {
      if (
        this.check_informations.media_plans.length
        && this.check_informations.opportunities.length
      ) {
        return true
      }
    }

    return false
  }

  public get same_types() {
    if (!this.change_type.origin.value || !this.change_type.origin.type) {
      return false
    }
    if (this.change_type.origin.type && this.change_type.new_type) {
      return this.change_type.origin.type === this.change_type.new_type
    }

    return false
  }

  public get selected_both_inputs() {
    return (
      (this.tabAction === 'merge_business_entity'
        && this.entity_changes.destination_entity.name
        && this.entity_changes.old_entity.name)
      || (this.tabAction === 'udpate_business_entity_type'
        && this.change_type.origin.value
        && this.change_type.new_type)
    )
  }

  public get selected_same_inputs() {
    if (!this.entity_changes.destination_entity.value || !this.entity_changes.old_entity.value) {
      return false
    }

    return this.entity_changes.destination_entity.value === this.entity_changes.old_entity.value
  }

  public get selected_diff_types() {
    if (!this.entity_changes.destination_entity.type || !this.entity_changes.old_entity.type) {
      return false
    }

    return this.entity_changes.destination_entity.type !== this.entity_changes.old_entity.type
  }

  public get selected_same_entity() {
    if (
      this.tabAction === 'udpate_business_entity_type'
      || !this.entity_changes.destination_entity.value
      || !this.entity_changes.old_entity.value
    ) {
      return false
    }

    return this.entity_changes.destination_entity.value === this.entity_changes.old_entity.value
  }

  public cancelChangeType() {
    this.modal.confirm = false
    this.confirmed = false

    this.change_type = {
      origin: {
        value: null,
      },
      new_type: null,
    }
  }

  public migrateAccountManager() {
    this.loading = true
    SystemToolsModules.mergeAccountManager(this.account_manager_swap)
      .then(res => {
        WebMessage.success('Account Manager migrated successfully!')
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }

  public confirmation() {
    if (this.tabAction === 'import_entities') {
      const formData = new FormData()
      for (let i = 0; i < this.local_files.length; i++) {
        formData.append('import_files[]', this.local_files[i])
      }
      return SystemToolsModules.importEntities(this.import_entity_type, formData).then(res => {
        WebMessage.success(
          'File processed successfully! Please review all data to make sure everything is correct.',
        )
        this.local_files = []

        this.loading = false
      })
    }

    if (this.tabAction === 'resync_metrics') {
      this.loading = true
      return SystemToolsModules.resyncMetrics(
        this.range_date.start_date_string,
        this.range_date.end_date_string,
      ).then(res => {
        WebMessage.success('Resync queued, please wait a few minutes for the process to finish.')
        this.loading = false
      })
    }

    this.checking = true
    this.modal.confirm = true
    let value = null
    let type = null
    let return_list = false

    if (this.tabAction === 'udpate_business_entity_type') {
      value = this.change_type.origin.value
      type = this.change_type.origin.type
      return_list = true
    } else if (
      this.tabAction === 'merge_business_entity'
      || this.tabAction === 'update_account_manager'
    ) {
      value = this.entity_changes.old_entity.value
      type = this.tabAction === 'update_account_manager'
        ? 'account_manager'
        : this.entity_changes.old_entity.type
    }

    this.check_informations = {
      media_plans: 0,
      opportunities: 0,
      invoices: 0,
      line_items: 0,
      orders: 0,
    }
    SystemToolsModules.checkEntity(value, type, return_list, this.entity_changes.cutout_date).then(
      res => {
        this.check_informations = res.data

        setTimeout(() => {
          this.checking = false
        }, 1000)
      },
    )
  }

  public cancelMerge() {
    this.modal.confirm = false
    this.confirmed = false

    this.entity_changes = {
      destination_entity: {
        name: null,
        type: null,
      },
      old_entity: {
        name: null,
        type: null,
      },
    }
  }

  public mergeEntities() {
    this.loading = true

    let payload = clone(this.entity_changes)
    if (this.tabAction === 'update_account_manager') {
      payload.old_entity.type = 'account_manager'
      payload.destination_entity.type = 'account_manager'
    }

    SystemToolsModules.mergeEntities(payload)
      .then(res => {
        WebMessage.success(
          this.tabAction === 'update_account_manager'
            ? 'Account Manager updated succecefully! Please review all data to make sure everything is correct. Some data may take a few hours to update.'
            : "Entity's merged successfully! Please review all data to make sure everything is correct. Some data may take a few hours to update.",
        )
        this.cancelMerge()

        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }

  public updateType() {
    this.loading = true
    SystemToolsModules.updateType(this.change_type)
      .then(res => {
        WebMessage.success(
          "Entity's type updated successfully! Please review all data to make sure everything is correct. Some data may take a few hours to update.",
        )
        this.cancelChangeType()
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }
}
