import Api from './Api'

export default class SystemToolsModules {
  public static checkEntity(
    entity_id: any,
    type: any,
    return_list: boolean = false,
    cutout_date: any = null,
  ) {
    if (type === 'default') {
      type = 'account_manager'
    }
    const api = new Api()
    return api.post('system-tools/check-entity', {
      entity_id,
      type,
      return_list,
      cutout_date,
    })
  }

  public static mergeEntities(payload: any) {
    const api = new Api()

    if (payload.old_entity.type === 'default' && payload.destination_entity.type === 'default') {
      payload.old_entity.type = 'account_manager'
      payload.destination_entity.type = 'account_manager'
    }

    return api.post('system-tools/merge-entities', payload)
  }

  public static updateType(payload: any) {
    const api = new Api()
    return api.post('system-tools/update-entity-type', payload)
  }

  public static mergeAccountManager(payload: any) {
    const api = new Api()
    return api.post('system-tools/update-entity-type', payload)
  }

  public static importEntities(entity: string, payload: any) {
    const api = new Api()
    return api.form(`system-tools/import/${entity}`, payload)
  }

  public static resyncMetrics(start: string, end: any) {
    const api = new Api()
    return api.post(`system-tools/resync-metrics/${start}/${end}`)
  }
}
